var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "equipment" }, [
    _c(
      "div",
      { staticClass: "e_content bgFFF" },
      [
        _c("h2", { staticClass: "title" }, [
          _c("div", { staticClass: "title_icon" }),
          _vm._v(" " + _vm._s(_vm.isEdit ? "修改" : "添加") + "资产信息 "),
        ]),
        _c(
          "el-form",
          {
            ref: "ruleForm2",
            staticClass: "demo-ruleForm",
            staticStyle: { width: "320px" },
            attrs: {
              model: _vm.formInline,
              rules: _vm.rules2,
              "label-width": "110px",
            },
          },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t("searchModule.Merchant_affiliation"),
                  prop: "operationId",
                },
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: { filterable: "", disabled: _vm.isEdit },
                    model: {
                      value: _vm.formInline.operationId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "operationId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.operationId",
                    },
                  },
                  _vm._l(_vm.tenantList, function (value) {
                    return _c("el-option", {
                      key: value.operationId,
                      attrs: {
                        label: value.operationName,
                        value: value.operationId,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t("searchModule.type"),
                  prop: "equipmentType",
                },
              },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.formInline.equipmentType,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "equipmentType",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.equipmentType",
                    },
                  },
                  [_c("el-option", { attrs: { label: "PDA", value: 1 } })],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "资产名称", prop: "equipmentName" } },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: 20,
                    type: "text",
                    "auto-complete": "off",
                  },
                  model: {
                    value: _vm.formInline.equipmentName,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formInline,
                        "equipmentName",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "formInline.equipmentName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "资产编码", prop: "equipmentCode" } },
              [
                _c("el-input", {
                  attrs: { maxlength: 20 },
                  model: {
                    value: _vm.formInline.equipmentCode,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formInline,
                        "equipmentCode",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "formInline.equipmentCode",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "型号", prop: "equipmentModel" } },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: 20,
                    type: "text",
                    "auto-complete": "off",
                  },
                  model: {
                    value: _vm.formInline.equipmentModel,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formInline,
                        "equipmentModel",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "formInline.equipmentModel",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t("searchModule.remarks"),
                  prop: "description",
                },
              },
              [
                _c("el-input", {
                  attrs: { type: "text", "auto-complete": "off" },
                  model: {
                    value: _vm.formInline.description,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formInline,
                        "description",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "formInline.description",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitData } },
                  [_vm._v("提交")]
                ),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }