<template>
    <div class='equipment'>
        <!-- <div  class="breadcrumb"></div> -->
        <div class='e_content bgFFF'>
            <h2 class="title">
                <div class="title_icon"></div>
                {{isEdit ? '修改' : '添加'}}资产信息
            </h2>
        <el-form :model="formInline" :rules="rules2" ref="ruleForm2" label-width="110px" class="demo-ruleForm" style="width: 320px;">
                <el-form-item  :label="$t('searchModule.Merchant_affiliation')" prop='operationId'>
                  <el-select v-model.trim="formInline.operationId" filterable :disabled="isEdit">
                    <el-option :label="value.operationName" :value="value.operationId" :key="value.operationId" v-for="value in tenantList"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('searchModule.type')" prop="equipmentType">
                    <el-select disabled v-model.trim="formInline.equipmentType" style="width: 100%;">
                        <el-option label="PDA" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="资产名称" prop="equipmentName">
                    <el-input :maxlength="20" type="text" v-model.trim="formInline.equipmentName" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="资产编码" prop="equipmentCode">
                    <el-input :maxlength="20" v-model.trim="formInline.equipmentCode"></el-input>
                </el-form-item>
                <el-form-item label="型号" prop="equipmentModel">
                   <el-input :maxlength="20" type="text" v-model.trim="formInline.equipmentModel" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('searchModule.remarks')" prop="description">
                   <el-input type="text" v-model.trim="formInline.description" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitData">提交</el-button>
                    <el-button @click="$router.go(-1)">取消</el-button>
                </el-form-item>
        </el-form>
        </div>
    </div>
</template>
<script>
    export default {
        data () {
       return {
            isEdit: false,
            oldName: '',
            oldCode: '',
            equipmentId: '',
            equipmentType: [],
            equipmentModel: [],
            tenantList: [],
            traceList: [],
            formInline: {
                    operationId: '',
                    equipmentName: '',
                    equipmentType: 1,
                    equipmentCode: '',
                    equipmentModel: '',
                    description: ''
                    },
            rules2: {
                    operationId: [
                        {
                            required: true,
                            message: '请选择商户',
                            trigger: 'blur'
                        }
                    ],
                    equipmentType: [
                        {
                            required: true,
                            trigger: 'blur'
                        }
                    ],
                     equipmentName: [
                        {
                            required: true,
                            message: '请输入资产名称',
                            trigger: 'blur'
                        }
                    ],
                    equipmentCode: [
                        {
                            required: true,
                            message: '请输入资产编码',
                            trigger: 'blur'
                        }
                    ]
                    }
              };
        },
        methods: {
            getOptions () {
                let url = '/acb/2.0/equipment/getAddOption';
                this.$axios.get(url).then(res => {
                    if (res.state == 0) {
                        this.equipmentType = res.value['equipmentType'];
                        this.traceList = res.value['traceTag']
                    } else {
                         this.$alert(res.desc, this.$t('pop_up.Tips'), {
                            confirmButtonText: this.$t('pop_up.Determine')
                        })
                    }
                })
            },
            /* 商户名称的下拉框 */
            getTenantList () {
                this.$axios.get('/acb/2.0/operation/nameList').then(res => {
                if (res.state == 0) {
                    this.tenantList = res.value;
                } else {
                    this.$alert(res.desc, this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine')
                    });
                }
                })
            },
            getDetail () {
                let url = '/acb/2.0/equipment/detail';
                this.$axios.get(url, {
                    data: {
                        equipmentId: this.$route.query.equipmentId
                    }
                }).then(res => {
                    if (res.state == 0) {
                        for (let i in this.formInline) {
                            this.formInline[i] = res.value[i]
                        }
                    } else {
                         this.$alert(res.desc, this.$t('pop_up.Tips'), {
                            confirmButtonText: this.$t('pop_up.Determine')
                        })
                    }
                })
            },
            getBrand () {
                let url = '/acb/2.0/brand/selectList'
                this.$axios.get(url).then(res => {
                    if (res.state == 0) {
                        this.equipmentModel = res.value;
                    } else {
                         this.$alert(res.desc, this.$t('pop_up.Tips'), {
                            confirmButtonText: this.$t('pop_up.Determine')
                        })
                    }
                })
            },
            submitData () {
                let data = {};
                if (this.isEdit) {
                    data = {
                        equipmentId: this.$route.query.equipmentId,
                        ...this.formInline
                    }
                } else {
                    data = this.formInline;
                }
                let url = this.isEdit ? '/acb/2.0/equipment/update' : '/acb/2.0/equipment/add'
                let method = 'post'
                 this.$refs['ruleForm2'].validate((valid) => {
                    if (valid) {
                        this.$axios[method](url, {
                            data: data
                        }).then(res => {
                            if (res.state == 0) {
                                this.$alert('操作成功！', this.$t('pop_up.Tips'), {
                                    confirmButtonText: this.$t('pop_up.Determine')
                                }).then(() => {
                                    this.$router.go(-1);
                                })
                            } else {
                                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                                    confirmButtonText: this.$t('pop_up.Determine')
                                })
                            }
                        })
                    } else {
                        return false;
                    }
                    });
            }
        },
        created () {
            this.getOptions();
            this.getBrand();
            this.getTenantList();
            if (this.$route.query.equipmentId) {
                this.isEdit = true;
                this.getDetail()
            }
        },
        mounted () {
    }
    }
</script>
<style scoped>
    .equipment .e_content{
        border: 1px solid #c0ccda;
        border-radius: 4px;
        min-height: 550px;
    }
    .equipment .e_content .demo-ruleForm{
        width:350px;
    }
    .breadcrumb {
       height: 35px;
    }
    .title {
        padding-top: 10px;
        padding-left:20px;
    }
</style>
